<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <a-button size='small' @click="()=>{$router.go(-1)}">
        <LeftOutlined />返回
      </a-button>
      <span class="ml15 card_title" v-if='$route.query.type == "look"'>品牌商详情</span>
      <span class="ml15 card_title" v-else>{{$route.query.type == 'add' ? '添加':'修改'}}品牌商</span>
    </a-card>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="company" label="公司名称" name="company">
          <a-input v-model:value="form.company" placeholder="请输入公司名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="contact_name" label="联系人" name="contact_name">
          <a-input v-model:value="form.contact_name" placeholder="请输入品牌商联系人名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="contact_phone" label="手机号" name="contact_phone" maxlenght="11">
          <a-input v-model:value="form.contact_phone" placeholder="请输入品牌商联系人的手机号" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="brand_no" label="账号" name="account">
          <a-input v-model:value="form.account" placeholder="请输入账号（数字,英文）" v-inputFilter:sn :readonly='$route.query.type =="look" || $route.query.type =="edit"' />
        </a-form-item>
        <a-form-item label="是否修改密码" v-if='$route.query.type =="edit"'>
          <a-radio-group v-model:value="is_edit" :disabled='$route.query.type == "add"'>
            <a-radio value="1">
              否
            </a-radio>
            <a-radio value="2">
              是
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item ref="password" label="密码" name="password" help='(6-18位)' v-if='$route.query.type =="add"||($route.query.type !=="look"&&is_edit==2)'>
          <a-input v-model:value="form.password" placeholder="请输入密码" />
        </a-form-item>
        <a-form-item ref="brand_name" label="品牌名称" name="brand_name" :rules="[{ required: true, message: '请输入品牌商名称' }]">
          <a-input v-model:value="form.brand_name" placeholder="请输入品牌名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="address" label="详细地址" name="address">
          <div class="s_flex_bian_c s_wid100 mb15">
            <a-cascader :disabled='$route.query.type == "look"' v-model:value="region" :field-names="{ label: 'name', value: 'id', children: 'children' }" :options="cityList" placeholder="请选择地址" @change="onChangeAddress" />
          </div>
          <a-input v-model:value="form.address" placeholder='请输入详细地址' :readonly='$route.query.type == "look"' />
        </a-form-item>

        <a-form-item label="小程序前缀">
          <a-input v-model:value="form.wxapp_prefix" :readonly='$route.query.type == "look"'>
            <template #addonBefore>
              <a-select v-model:value="wxValueL" style="width: 90px">
                <a-select-option value="http://">
                  http://
                </a-select-option>
                <a-select-option value="https://">
                  https://
                </a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item ref="industry" label="所属行业" name="industry">
          <a-select v-model:value="form.industry" placeholder="请选择所属行业" :disabled='$route.query.type == "look"'>
            <a-select-option v-for='item in industrysList' :key="item" :value="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="E-Mail">
          <a-input v-model:value="form.email" placeholder='请输入邮箱' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item ref="license_images" label="资质认证" name="license_images" help='图片大小不超过2M，支持jpg/png格式(最多上传两张)' :rules="[{ required: true, message: '请上传资质认证' }]">
          <a-upload v-model:fileList="licenseImages" name="file" accept='image/jpeg,image/png,image/jpg' list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="(e)=>ImagesChange(e,'license_images')" @preview="handlePreview" :beforeUpload="beforeUpload" :disabled='$route.query.type == "look"'>
            <div v-if='licenseImages.length < 2'>
              <plus-outlined />
              <div class="ant-upload-text">上传资质认证</div>
            </div>
          </a-upload>
          <div class="ant-form-explain">请上传有效期内且年检章齐全的营业执照清晰彩色原件扫描件。</div>
        </a-form-item>
        <a-form-item ref="license_no" label="营业执照号码" name="license_no" :help='form.license_type == 1 ? "请输入15位营业执照注册号或18位的统一社会信用代码。":"请输入10-20位的营业执照号码，可支持英文、数字和“-”。"'>
          <a-input-group compact>
            <a-select style="width: 15%" v-model:value="form.license_type">
              <a-select-option value="1">中国</a-select-option>
              <a-select-option value="2">国外</a-select-option>
            </a-select>
            <a-input style="width: 85%" v-model:value="form.license_no" placeholder='营业执照号码' />
          </a-input-group>
        </a-form-item>
        <a-form-item ref="bar_code" label="组织机构代码" name="bar_code">
          <a-input v-model:value="form.bar_code" placeholder='请输入组织机构代码' />
        </a-form-item>
        <a-form-item ref="business_addr" label="企业地址" name="business_addr">
          <a-input v-model:value="form.business_addr" placeholder='请输入企业地址' />
        </a-form-item>
        <a-form-item label="品牌商标题LOGO" name="license_images" help='图片大小不超过2M，支持jpg/png格式(最多上传两张)'>
          <a-upload v-model:fileList="brand_mark" name="file" accept='image/jpeg,image/png,image/jpg' list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="(e)=>ImagesChange(e,'brand_mark')" @preview="handlePreview" :beforeUpload="beforeUpload" :disabled='$route.query.type == "look"'>
            <div v-if='brand_mark.length < 1'>
              <plus-outlined />
              <div class="ant-upload-text">请上传品牌商标题LOGO</div>
            </div>
          </a-upload>
          <div class="ant-form-explain">尺寸推荐:602*240</div>
        </a-form-item>
        <a-form-item label="品牌商LOGO" name="license_images" help='图片大小不超过2M，支持jpg/png格式(最多上传两张)'>
          <a-upload v-model:fileList="brand_logo" name="file" accept='image/jpeg,image/png,image/jpg' list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="(e)=>ImagesChange(e,'brand_logo')" @preview="handlePreview" :beforeUpload="beforeUpload" :disabled='$route.query.type == "look"'>
            <div v-if='brand_logo.length < 1'>
              <plus-outlined />
              <div class="ant-upload-text">请上传品牌商LOGO</div>
            </div>
          </a-upload>
          <div class="ant-form-explain">尺寸推荐:88*88</div>
        </a-form-item>
        <a-form-item required label="功能模块" v-if='form.apps.length > 0 && $route.query.type !=="add"'>
          <a-form-item :label="item.app_name" class="m-b-20" v-for='item in form.apps' :key='item'>
            <div class="s_flex_ali">
              <a-input v-model:value="item.real_valid_year" readonly style="width:200px" v-if="form.app_fees_type!=1" />
              <div class="m-l-20 s_c666">{{form.app_fees_type!=1?'年,':''}}本项合计：{{item.real_price}}元（价格）</div>
            </div>
          </a-form-item>
        </a-form-item>
        <a-form-item required label="功能模块" name="" v-if='$route.query.type =="add"'>
          <a-radio-group v-model:value="form.app_fees_type" @change='resetFee'>
            <a-radio value="0">
              按功能模块收费
            </a-radio>
            <a-radio value="1">
              按平台全包
            </a-radio>
          </a-radio-group>
          <div v-if="form.app_fees_type==0">
            <a-checkbox-group v-model:value="form.apps" @change='selectChange'>
              <div class="p-t-10" v-for='(item,index) in modulesearsList' :key='item.id'>
                <a-checkbox :value="item.mark_id" style="margin-bottom:5px" :disabled="item.ischecked">
                  {{item.name}}
                  <a-select v-model:value="item.appYears" placeholder="请选择年限" style='width:200px;margin-left:10px' @change='(e)=>selectYearChange(e,index)' v-if="item.apps">
                    <a-select-option v-for='item in item.apps' :key="item.id" :value="item.id">
                      {{item.valid_year}}年
                    </a-select-option>
                  </a-select>
                  <span v-if='item.discount_price'>（本项合计：{{item.discount_price}}元）</span>
                </a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
          <a-form-item label="" v-if="form.app_fees_type==1">
            <a-select placeholder="请选择年限" style='width:200px;margin-left:10px' @change="changeYear" v-model:value="form.app_year">
              <a-select-option :value="v.valid_year" v-for="v in app_type_all.serve_list" :key="v.id">
                {{v.valid_year}}年
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="服务费" >
           1000
          </a-form-item> -->
        </a-form-item>
        <a-form-item required label="是否允许使用海外地址" name="" >
          <a-radio-group v-model:value="form.is_open_gw">
            <a-radio :value="1">
              允许
            </a-radio>
            <a-radio :value="0">
              不允许
            </a-radio>
          </a-radio-group>
        </a-form-item>
         <a-form-item required label="产线" name="" >
          <a-radio-group v-model:value="form.is_production_line">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="收费合计" v-if=' $route.query.type =="add"'>
          <div style="color:#e0001b">{{servicePrice}}元</div>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 14, offset: 3 }" v-if='$route.query.type !=="look"'>
          <a-button type="primary" @click="onSubmit($route.query.type)">
            {{$route.query.type =="add"?'开户':'修改'}}
          </a-button>
        </a-form-item>
        <a-modal v-model:visible="visible" centered title="请输入密码" @ok="onPayment" @cancel='form.pay_password=""'>
          <a-input-password v-model:value="form.pay_password" placeholder="请输入支付密码" />
        </a-modal>
      </a-form>
    </a-card>
  </a-layout>

  <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>

  <a-modal :visible="confirmShow" title="开户确认" @ok="onPayment()" @cancel="confirmShow=false">
    <div style="paddingLeft:40px">
      <div class="m-t-15">公司名称：{{form.company}}</div>
      <div class="m-t-15">账号：{{form.account}}</div>
      <div class="m-t-15">收费方式：{{form.app_fees_type==0?'按功能模块收费':'按平台全包'}}</div>
      <div class="m-t-15">收费合计：<span class="s_c666">{{servicePrice}}元</span></div>
    </div>
  </a-modal>
</template>

<script>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import {
  getBrandtMore,
  brand_create,
  brand_update,
  getBrandIndustrys,
  getBrandAppByYear,
} from "@/api/brand";
import { getArea } from "@/api/public";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import {
  getappServeFees,
  brandGetAppCost,
  brandGetCareateAppPrice,
} from "@/api/product";
import config from "@/config";
import { getToken } from "@/libs/util";
const baseUrl = config.baseUrl[process.env.VUE_APP_type];
const header = { apiAuth: getToken() };
// 初始化默认筛选项数值
let defForm = {
  license_type: "1",
  business_addr: "",
  license_no: "",
  bar_code: "",
  company: "",
  brand_name: "",
  account: "",
  password: "123456",
  province: undefined,
  city: undefined,
  region: undefined,
  address: "",
  industry: undefined,
  email: "",
  app_id: [],
  app_fees_type: "1",
  account_fee: 0,
  wxapp_prefix: "",
  apps: [],
  resource: "1",
  brand_logo: "",
  brand_mark: "",
  pay_password: "",
  license_images: [],
  app_year: null,
  app_marks: [],
  is_open_gw:0,
  is_production_line:0,
};
export default {
  components: { LeftOutlined },
  setup() {
    const form = ref({ ...defForm });

    let validatePass = async (rule, value) => {
      if (form.value.app_fees_type == 1) {
        if (value) {
          return Promise.resolve();
        } else {
          return Promise.reject("请选择年限!");
        }
      } else {
        return Promise.resolve();
      }
    };
    const state = reactive({
      previewImage: "",
      previewVisible: false,
      header,
      licenseImages: [],
      brand_logo: [],
      brand_mark: [],
      baseUrl,
      visible: false,
      agentData: {},
      allPrice: 0,
      surplusPrice: 0,
      urlValueL: "http://",
      wxValueL: "http://",
      address: [],
      region: [],
      industrysList: [],
      modularList: [],
      cityList: [],
      moreData: {},
      labelCol: { span: 3 },
      wrapperCol: { span: 12 },
      app_idArr: [],
      is_edit: "1",
      appByList: [],
      appYears: "",
      yearsList: [],
      serveSelect: [],
      appList: [],
      modulesearsList: [],
      appData: {},
      app_type_all: {}, //全包
      app_type_module: {}, //自选
      allCheckbox: [], //模块钩选
      confirmShow: false,
      servicePrice: 0, //服务费
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 6,
            max: 50,
            message: "公司名称长度在6到50个字符之间",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "请输入品牌商联系人名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 10,
            message: "代理商联系人名称长度在2到10个字符之间",
            trigger: "blur",
          },
        ],
        contact_phone: [
          {
            required: true,
            message: "请输入品牌商联系人手机号",
            trigger: "blur",
          },
          { len: 11, message: "品牌商联系人手机号长度有误", trigger: "blur" },
        ],
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
      },
    });
    const ruleForm = ref();
    const $route = inject("$route");
    const $Modal = inject("$Modal");
    const $router = inject("$router");
    const _lodash = inject("_lodash");
    //获取详情
    const getMore = async (id) => {
      const hide = message.loading("正在加载中...", 0);
      let res = await getBrandtMore(id)
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        res.data.wxapp_prefix = _lodash.split(res.data.wxapp_prefix, "://")[1];
        form.value = res.data;
        state.region = [res.data.province, res.data.city, res.data.region];
        form.value["brand_mark"] = res.data.brand_mark;
        state.brand_logo = [res.data.brand_mark];
        state.brand_mark = [res.data.brand_logo];
        form.value["brand_logo"] = res.data.brand_logo;

        setTimeout(hide);
      } else {
        message.error(res.msg);
      }
    };

    //
    const getAppCost = async function () {
      let res = await brandGetAppCost()
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        state.app_type_all = res.data.app_type_all || {};
        state.app_type_module = res.data.app_type_module || {};
        console.log(res, "11");
      } else {
        message.error(res.msg);
      }
    };

    //获取服务模块
    const getappServe = async () => {
      let result = await getappServeFees()
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result.data);
      state.appData = result.data;
      let res = await brandGetAppCost({ nopages: 1 })
        .then((res) => res.data)
        .catch((error) => error);
      state.modulesearsList = res.data.app_type_module.app_list;
      state.modulesearsList.forEach((item) => {
        if (item.app_type == 1) {
          console.log(item);
          form.value.apps.push(item.mark_id);
          item.ischecked = true;
        } else {
          item.ischecked = false;
        }
      });
      console.log(form.value.apps);
    };

    //获取省市区
    const getAreaList = async () => {
      let result = await getArea()
        .then((res) => res)
        .catch((error) => error);
      state.cityList = result.data;
    };
    //选择省市区
    const onChangeAddress = (e) => {
      form.value.province = e[0];
      form.value.city = e[1];
      form.value.region = e[2];
    };
    //获取代理商行业
    const getIndustrys = async () => {
      let res = await getBrandIndustrys()
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        state.industrysList = res.data;
      } else {
        message.error(res.msg);
      }
    };
    //获取可用年限的功能模块
    const getModular = async (app_id) => {
      let res = await getBrandAppByYear({ app_id })
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        for (var i in state.appList) {
          if (state.appList[i].app_id == app_id) {
            state.appList[i].yearsList = res.data;
            state.appList[i].appYears = state.appList[i].appYears
              ? state.appList[i].appYears
              : "";
          }
        }
        state.allPrice = 0;
        countPrice();
      } else {
        message.error(res.msg);
      }
    };
    const resetFee = () => {
      state.servicePrice = 0; //服务费
      state.allPrice = 0;
      state.surplusPrice = 0;
      form.value.app_year = 1;
      state.allCheckbox = [];
      //计算价格
      var data = {};
      if (form.value.app_fees_type == 1) {
        data = {
          mode: 1,
          mark_ids: "",
          real_valid_year: form.value.app_year,
        };
        countModulePrice(data);
      }
    };
    // 选择功能模块年限
    const selectYearChange = (e, index) => {
      state.modulesearsList[index].appYears = e;
      state.modulesearsList[index].discount_price = _lodash.round(
        Number(state.modulesearsList[index].sys_price),
        2
      );
      countPrice();
    };
    //选择功能模块
    const selectChange = (e) => {
      console.log(e);
      if (e.length > 0) {
        countModulePrice({
          mode: 2,
          mark_ids: e,
          real_valid_year: "",
        });
      } else {
        state.allPrice = "0.00";
      }
    };
    const handleChange = () => {
      servePrice();
    };

    //计算服务费
    var countModulePrice = async function (data) {
      const hide = message.loading("正在加载中...", 0);
      let res = await brandGetCareateAppPrice(data)
        .then((res) => res.data)
        .catch((error) => error); //11111
      setTimeout(hide);
      if ($iscode(res)) {
        state.servicePrice = res.data || 0;
      } else {
        message.error(res.data);
      }
    };
    //自选模块
    const changeCheckbox = function (e) {
      state.allCheckbox = e || [];
      const data = {
        mode: 0,
        mark_ids: e,
        real_valid_year: "",
      };
      countModulePrice(data);
    };
    //年限
    const changeYear = function (e) {
      const data = {
        mode: 1,
        mark_ids: "",
        real_valid_year: form.value.app_year,
      };
      countModulePrice(data);
    };

    const servePrice = () => {
      var price = 0;
      for (var i in state.appList) {
        for (var s in form.value.apps) {
          if (state.appList[i].id == form.value.apps[s]) {
            price +=
              Number(state.appList[i].serve_price) +
              Number(state.appList[i].sys_price);
          }
        }
      }
      // console.log(price);
      state.allPrice = price.toFixed(2);
    };
    //计算价格
    const countPrice = () => {
      var price = 0;
      for (var i in state.modulesearsList) {
        for (var s in form.value.apps) {
          if (state.modulesearsList[i].id == form.value.apps[s]) {
            state.modulesearsList[i].apps.forEach((element) => {
              if (state.modulesearsList[i].appYears == element.id) {
                price += Number(element.sys_price);
              }
            });
          }
        }
      }
      state.allPrice = price.toFixed(2);
      state.surplusPrice = _lodash
        .subtract(Number(state.agentData.balance), price)
        .toFixed(2);
    };
    //提交
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(() => {
          // onPayment();
          state.confirmShow = true;
        })
        .catch((error) => {
          message.error("请完善表单");
        });
    };
    const onPayment = async () => {
      let res;
      const hide = message.loading("正在加载中...", 0);
      var app_ref_ids = form.value.apps;
      if ($route.query.type == "add") {
        form.value.app_marks = state.allCheckbox || [];
        res = await brand_create({
          ...form.value,
          wxapp_prefix: form.value.wxapp_prefix
            ? state.wxValueL + form.value.wxapp_prefix
            : "",
          app_id: state.app_idArr,
          app_ref_ids: _lodash.pull(app_ref_ids, "0"),
          app_marks: _lodash.pull(app_ref_ids, "0"),
          app_is_super: form.value.app_fees_type,
        })
          .then((res) => res.data)
          .catch((error) => error);
      } else {
        res = await brand_update({
          ...form.value,
          wxapp_prefix: form.value.wxapp_prefix
            ? state.wxValueL + form.value.wxapp_prefix
            : "",
          app_id: state.app_idArr,
          app_ref_ids: _lodash.pull(app_ref_ids, "0"),
          app_marks: _lodash.pull(app_ref_ids, "0"),
          app_is_super: form.value.app_fees_type,
        })
          .then((res) => res.data)
          .catch((error) => error);
      }
      setTimeout(hide);
      if ($iscode(res, true)) {
        setTimeout(() => {
          state.confirmShow = false;
          $router.go(-1);
        }, 1000);
      }
    };
    onMounted(() => {
      getAreaList();
      getIndustrys();
      getappServe();
      getAppCost();
      if ($route.query.type == "edit" || $route.query.type == "look") {
        getMore($route.query.id);
      } else {
        let rulesObj = {
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 20,
              message: "密码长度在6到20个字符之间",
              trigger: "blur",
            },
          ],
        };
        state.rules = _lodash.merge(state.rules, rulesObj);
      }
    });

    // 上传资质认证 前
    const beforeUpload = (file, fileList) => {
      if (fileList.length > 2) {
        message.error("最多上传二张图");
        return false;
      }
    };
    // 上传 图片
    const ImagesChange = (info, type) => {
      if (info.file.status === "done") {
        if (type == "license_images") {
          state.licenseImages = info.fileList;

          form.value[type].push(info.file.response.data.path);
        } else {
          state[type] = info.fileList;
          form.value[type] = info.file.response.data.path;
        }
      }
      if (info.file.status === "error") {
        message.error("图片上传失败！");
      }
      if (info.file.status === "removed") {
        form.value.license_images = "";
      }
    };
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      state.previewImage = file.url || file.preview;
      state.previewVisible = true;
    };
    return {
      form,
      ruleForm,
      handlePreview,
      ...toRefs(state),
      onChangeAddress,
      onSubmit,
      getModular,
      selectChange,
      countPrice,
      onPayment,
      resetFee,
      selectYearChange,
      handleChange,
      beforeUpload,
      ImagesChange,
      changeCheckbox,
      changeYear,
    };
  },
};
</script>
<style>
</style>